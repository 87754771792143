import "mmenu-js"

// Init mobile Menu
document.addEventListener(
    "DOMContentLoaded", () => {
        const menuElement = document.getElementById('menu');
        if (!menuElement) {
            return;
        }
        const menu = new Mmenu( menuElement, {
            offCanvas: {
                "position": "right-front"
            },
            navbar: {
                "title": menuElement.dataset.title || "Undefined",
            },
            theme: "white",
            language: "de",
        });

        // Get the API
        const api = menu.API;
        document.getElementById("btn-toggle-nav").addEventListener("click", (event) => {
            event.preventDefault();
            api.open();
        });
    }
);

document.addEventListener("scroll", function() {
    const nav = document.querySelector('.navbar');
    const navInverse = document.querySelector('.navbar-inverse');
    const navInverseButton = document.querySelector('.btn-navbar');
    const top = 1;

    if (window.scrollY >= top) {
        nav.classList.add('navbar-scroll');
        if (navInverse) {
            navInverse.classList.remove('navbar-dark');
        }
        if (navInverseButton) {
            navInverseButton.classList.add('btn-primary');
            navInverseButton.classList.remove('btn-outline-light');
        }
    } else {
        nav.classList.remove('navbar-scroll');
        if (navInverse) {
            navInverse.classList.add('navbar-dark');
        }
        if (navInverseButton) {
            navInverseButton.classList.add('btn-outline-light');
            navInverseButton.classList.remove('btn-primary');
        }
    }
});

(function() {
    let isOpen = false;

    const closeMenu = (event) => {
        if (event) {
            event.stopPropagation();
        }
        document.querySelectorAll('.nav-item-click').forEach(navItem => {
            navItem.classList.remove('active');
        });
        document.querySelectorAll('.modal-backdrop-menu').forEach(modalBackgrop => {
            modalBackgrop.classList.remove('show');
        });
        isOpen = false;
    }

    const navItems = document.querySelectorAll('.nav-item-click');
    navItems.forEach((navItem) => {
        navItem.addEventListener("click", function () {
            if (!navItem.classList.contains("active")) {
                navItems.forEach(x => x.classList.remove('active'));
                navItem.classList.toggle('active');
                document.querySelectorAll('.modal-backdrop-menu').forEach(element => {
                  element.classList.add('show');
                });
                isOpen = true;
            }
        });
    });

    document.addEventListener("click", function(event) {
        const element = event.target;
        const activeItem = element.closest('.nav-item-click.active');

        if (isOpen && !activeItem) {
            closeMenu();
        }
    });

    document.querySelectorAll('.nav-item-mega-close').forEach(element => {
        element.addEventListener("click", closeMenu);
    });
}());
