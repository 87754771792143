import scrollSpy from 'simple-scrollspy'
import jumpTo from 'jump.js'

const options = {
  sectionClass: '.section',
  menuActiveTarget: 'a.list-group-item',
  offset: document.querySelector('nav.navbar').clientHeight + 25,
  smoothScroll: true,
  smoothScrollBehavior: function(element) {
    const navbarHeight = document.querySelector('nav.navbar').clientHeight;

    jumpTo(element, {
      duration: 1000,
      offset: -(navbarHeight + 20),
    })
  }
}

const element = document.getElementById("scrollspy");
if (element) {
  scrollSpy(element, options)
}
