/**
 * jQuery Needed
 */
import "./main/upload"

/**
 * No jQuery needed
 */
import "./main/bootstrap"
import "./main/menu"
import "./main/swiper" // ~4s
import "./main/lightbox"
import "./main/scrollTop" // 0.14s
import "./main/scrollspy" // 0.31s
import "./main/console"
