import Tooltip from 'bootstrap/js/dist/tooltip';
import Modal from "bootstrap/js/dist/modal";
import Collapse from "bootstrap/js/dist/collapse";
import Tab from "bootstrap/js/dist/tab";
import jumpTo from "jump.js";

/**
 * TOOLTIP
 */
const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new Tooltip(tooltipTriggerEl);
});

/**
 * MODAL
 */
document.querySelectorAll(".btn-modal-close").forEach(btn => {
  const parentModalElement = btn.closest(".modal");

  if (parentModalElement) {
    const parentModal = new Modal(parentModalElement);

    btn.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      parentModal.hide();

      const href = btn.getAttribute("href");
      if (href.startsWith("#")) {
        setTimeout(() => {
          const navbarHeight = document.querySelector('nav.navbar').clientHeight;
          const element = document.getElementById(href.substring(1));

          jumpTo(element, {
            duration: 500,
            offset: -(navbarHeight + 20),
          })
        }, 500);
      }
    });
  }
});
