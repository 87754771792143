import jumpTo from 'jump.js'

//Get the button:
const buttonScrollTop = document.getElementById("scrollTopBtn");

// When the user scrolls down 20px from the top of the document, show the button
function scrollFunction() {
  if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    buttonScrollTop.classList.add("active");
  } else {
    buttonScrollTop.classList.remove("active");
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  jumpTo(document.body, { duration: 1000, offset: 0, })
}

if (buttonScrollTop) {
  document.addEventListener("scroll", scrollFunction)
  buttonScrollTop.addEventListener("click", topFunction)
}
